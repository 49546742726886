const PAGE_PATHS = {
  DELETE: 'delete',
  DELETE_AGREEMENT: 'delete-agreement',
  DELETE_COMPLETE: 'delete-complete',
  DELETE_FAIL: 'delete-fail',
  MAIN: '',
  REGISTER: 'register',
  REGISTER_DONE: 'register-done',
  SOCIAL_REGISTER_DONE: 'social-register-done',
  CONNECT_DONE: 'connect-done',
};

const kakaotalkEscapeUrl = 'kakaotalk://web/openExternal';

const loginProblemMailScheme = {
  ko: 'mailto:support@vaultmicro.zendesk.com?subject=%5B%EB%B2%84%EA%B7%B8%20%EC%8B%A0%EA%B3%A0%5D%5B%EC%B9%B4%EB%A9%94%EB%9D%BC%ED%8C%8C%EC%9D%B4%20%EB%A1%9C%EA%B7%B8%EC%9D%B8%5D&body=1.%20%EB%A1%9C%EA%B7%B8%EC%9D%B8%20%EA%B3%84%EC%A0%95%3A%20%0A2.%20%EB%A1%9C%EA%B7%B8%EC%9D%B8%20%EB%B0%A9%EB%B2%95(%EC%98%88%3A%20%EA%B5%AC%EA%B8%80%EB%A1%9C%20%EB%A1%9C%EA%B7%B8%EC%9D%B8)%3A%20%0A3.%20%EB%AC%B8%EC%9D%98%20%EB%82%B4%EC%9A%A9%3A%20',
  en: 'mailto:support@vaultmicro.zendesk.com?subject=%5BBug%20Report%5D%5BCameraFi%20Login%5D&body=1.%20Login%20account%3A%20%0A2.%20Login%20method(e.g.%2C%20Log%20in%20with%20Google)%3A%20%0A3.%20Inquiry%20details%3A%20',
};

export { PAGE_PATHS, loginProblemMailScheme, kakaotalkEscapeUrl };
